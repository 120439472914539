<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <button class="btn btn-sm btn-primary" @click="addReceive()">
                  Tambah
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="fields"
          style="max-height: 300px; overflow: auto;"
        >
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              
              <CButton
                v-if="item.id != receive.id"
                @click="hapus(item)"
                color="danger"
                square
                size="sm"
              >
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="50"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      size="xl"
      :title="isUpdate ? 'Edit Receive' : 'Tambah Receive'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.code"
            label="Code"
            placeholder="Autonumber"
            disabled
          />
          <CInput
            v-model="form.remark"
            label="Catatan"
            placeholder="ketik disini"
          />
        </div>  
        <div class="col">
          <CInput
            v-model="form.date"
            label="Date"
            type="date"
            placeholder="ketik disini"
          />
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Nama Barang</th>
            <th>Satuan</th>
            <th>Stock</th>
            <th>Qty</th>
            <th> 
             
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in form.item_receive_details" :key="index">
            <td>
              <v-select
                v-model="item.item_id"
                placeholder="Pilih Barang"
                :options="computedBarang.filter((n) => n)"
                @input="changeUnitOut($event, index)"
              ></v-select>
            </td>
            <td>
              <CInput
                v-model="item.unit"
                placeholder="Satuan"
                type="text"
                disabled
              />
            </td>
            <td>
              <CInput
                v-model="item.stock"
                placeholder="stock"
                type="text"
                disabled
              />
            </td>
            <td>
              <CInput
                v-model="item.qty"
                placeholder="Qty"
                type="number"
              />
            </td>
            <td>
              <button @click="deleteBarang(index)" class="btn btn-sm btn-danger">
                Hapus
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="add" class="btn btn-sm btn-primary">
          + Tambah Barang
      </button>


      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/receive"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      createModal: false,
      fields: data.fields,
      isUpdate: false,
      items: [],
      users: [],
      regional_apparatuses: [],
      receive: [],
      page: 1,
      total: 0,
      form: {
        item_receive_details: []
      },
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
      barangs: [],
    }
  },
  methods: {
    changeUnitOut(unitValue, index) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("usage/getStock", unitValue.value)
          .then((resp) => {

            console.log(resp.data.length, this.form.item_receive_details)

            this.form.item_receive_details[index].stock = resp.data.length > 0 ? resp.data[0].qty : 0;
            this.form.item_receive_details[index].unit = unitValue.unit;
            const new_details = this.form.item_receive_details[index];
            this.$set(this.form.item_receive_details, index, new_details)
            
            loading.hide()
          })
          .catch((e) => {
            console.log(e)
            loading.hide()
          })
    },
    deleteBarang(index) {
      (this.form.item_receive_details.splice(index, 1))
    },
    onChange(event) {
      console.log(event, "event")
    },
    add() {
      this.form.item_receive_details.push({
        item_id: null,
        qty: 0,
      })
    },
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.profile_picture = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show()

      this.form.item_receive_details.forEach((element) => {
        element.item_id = element.item_id.value
      });
      
      this.$store
        .dispatch("receive/addReceive", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan Item")
          loading.hide()
          this.createModal = false
          // this.form = {
          //   item_receive_details: []
          // }
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      item.date = this.$moment(item.date, 'Do MMMM YYYY').format('YYYY-MM-DD');
      this.form = item
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()
      this.form.role_id = this.form.role ? this.form.role.value : null

      this.form.item_receive_details.forEach((element) => {
        element.item_id = element.item_id.value
      });
      
  
      this.$store
        .dispatch("receive/updateReceive", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data receive")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("receive/deleteReceive", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data receive")
            this.form = {
              item_receive_details: []
            }
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("receive/getReceive", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          console.log(this.item)

          loading.hide()

          this.getDataBarang()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataBarang() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("item/getItem", params)
        .then((resp) => {
          this.barangs = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    addReceive() {
      this.isUpdate = false
      this.form = {
        item_receive_details: []
      }
      this.createModal = true
    },
    changePassword(item) {
      this.modalPassword = true
      this.form.id = item.id
      this.form.full_name = item.full_name
      this.form.firebase_id = item.firebase_id
    },
    inputPassword() {
      this.invalidPassword = ""
      this.isPasswordValid = null
    },
    validatePassword(item) {
      if (item.password.length < 6) {
        this.invalidPassword = "Password kurang dari 6 karakter!!"
        this.isPasswordValid = false
        return false
      } else if (item.password != item.password_confirmation) {
        this.invalidPassword = "Konfirmasi password tidak sama!!"
        this.isPasswordValid = false
        return false
      } else {
        this.invalidPassword = ""
        this.isPasswordValid = null
        return true
      }
    },
    updatePassword() {
      if (this.validatePassword(this.form)) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("receive/changePassword", {
            id: this.form.id,
            password: this.form.password,
          })
          .then(() => {
            this.$toast.success("Berhasil merubah password receive")
            loading.hide()
            this.modalPassword = false
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      } else {
        this.$toast.error(this.invalidPassword)
      }
    },
    closeModalPassword() {
      this.form = {}
      this.invalidPassword = ""
      this.isPasswordValid = null
      this.modalPassword = false
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    deleteAccountByReceiveId(receiveId) {
      firebase
        .auth()
        .getReceive(receiveId)
        .then((receiveRecord) => {
          // Hapus akun pengguna dengan metode delete()
          return firebase.auth().deleteReceive(receiveRecord.uid)
        })
        .then(() => {
          // Akun berhasil dihapus
          console.log("Akun berhasil dihapus berdasarkan ID pengguna:", receiveId)
        })
        .catch((error) => {
          // Tangani kesalahan jika ada
          console.error("Error saat menghapus akun:", error.message)
        })
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          ...item,
          user_name: item.user ? item.user.full_name : '-',
          date: this.$moment(item.date).format("Do MMMM YYYY"),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
    computedBarang() {
      if (this.form.item_receive_details.length > 0) {
        this.form.item_receive_details.forEach(element => {
          if (element.item_id && !element.item_id.value) {
            let label = this.barangs.find((element_barang) => {
              return element_barang.id == element.item_id
            });
            let barang = {
              value: element.item_id,
              label: label.name,
            }
            element.item_id = barang;
          }
        });
      }
      return this.barangs.map((item) => {
          return {
            value: item.id,
            label: item.name,
            unit: item.unit,
          };
      });
    },
  },
  mounted() {
    this.getData()
  },
}
</script>
